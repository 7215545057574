<template>
    <el-row style="margin-bottom: 20px">
        <el-col>
            <el-card>
                <div class="top-card">
                    <div class="business-info">
                        <el-avatar :src="userInfo.w_headimgurl" shape="square" :size="90" :fit="cover"></el-avatar>
                        <div class="info-content">
                            <div class="info-content-top">
                                <div class="company">{{ userInfo.company }}</div>
                                <router-link to="/member">
                                    <el-tag size="small" hit="true"><i class="iconfont icon-anchor-full rankIcon"></i>
                                        {{
                                            tools.getVipLevel(userInfo.integral)
                                        }}</el-tag>
                                </router-link>
                            </div>
                            <div class="info-content-center">企业管理员</div>
                            <div class="info-content-bottom">
                                <div>用户名：<span>{{ userInfo.name }}</span></div>
                                <div>手机号：<span>{{ userInfo.phone }}</span></div>
                                <div>邮箱：<span>{{ userInfo.email }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="invite">
                        <a href="https://yinocloud.yinolink.com/help/1181?type=1" class="guide">企业管理使用手册</a>
                        <div class="invite-box" @click="inviteAccount"
                            v-if="userInfo.crm_id !== 91122 && userInfo.crm_id !== 27563">
                            <img src="@/assets/images/business/invite.svg" alt="" />
                            <span>邀请企业成员</span>
                        </div>
                    </div>
                </div>
            </el-card>
        </el-col>
    </el-row>
    <el-row class="bottom-card">
        <el-col>
            <el-card :body-style="{ 'padding-top': '15px', 'min-height': cardHeight + 'px' }">
                <template #header>
                    <span class="SubHead" style="margin-right: 5px;">成员管理</span>
                    <el-tooltip class="item" effect="light" content="" placement="top">
                        <template #content>
                            <div>
                                工作伙伴通过链接加入企业后即可成为企业成员；
                            </div>
                            <div>
                                企业成员可与管理员一同查看并管理同企业下的广告账户及其他资产;
                            </div>
                            <div>
                                将成员添加到不同的用户组即可调整企业成员的资产管理权限。
                            </div>
                        </template>
                        <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                </template>
                <div class="empty" v-if="businessAccountList && businessAccountList.length !== 0">
                    <img src="@/assets/images/business/empty.png" alt="">
                    <div class="empty-title">您还没有企业子账户</div>
                    <div class="empty-info">请分享邀请链接，通过此链接注册登录的小伙伴，即可成功加入企业成为子账户</div>
                    <div class="invite-button" @click="inviteAccount">邀请企业成员</div>
                </div>
                <div v-else style="width: 100%;display: flex;">
                    <div class="left-content">
                        <div class="content-info">
                            <span>用户组</span>
                            <span>当前视图：{{ currentGroupIndex === 0 ? '全部成员' : currentGroupIndex === 1 ? '未分配成员' :
                                userGroup.user_group[currentGroupIndex - 2].name }}</span>
                        </div>
                        <div class="group-container" :style="{ 'height': '100%' }">
                            <div class="add-group"
                                @click="dialogVisible = true; dialogType = 'addGroup'; groupForm.name = ''; groupForm.id = null">
                                <img src="@/assets/images/business/plus.svg" alt="">
                                <span>新建用户组</span>
                            </div>
                            <div class="group-list">
                                <div class="group-item" :class="currentGroupIndex === 0 && 'active-group'"
                                    @click="groupIndexChange(0)">
                                    <span>全部成员</span>
                                    <span>{{ userGroup.all_num }}</span>
                                </div>
                                <div class="group-item" :class="currentGroupIndex === 1 && 'active-group'"
                                    @click="groupIndexChange(1)">
                                    <span>未分配成员</span>
                                    <span>{{ userGroup.num }}</span>
                                </div>
                                <div class="group-item" v-for="(item, index) in userGroup.user_group" :key="item.id"
                                    :class="(currentGroupIndex === index + 2) && 'active-group'"
                                    @click="groupIndexChange(index + 2)">
                                    <span>{{ item.name }}</span>
                                    <div>
                                        <el-popover width="160" popper-class="popover">
                                            <div style="display: flex;flex-direction: column;text-align: left;">
                                                <div style="color: #595959;cursor: pointer;"
                                                    @click="dialogVisible = true; dialogType = 'editGroup'; groupForm.name = item.name; groupForm.id = item.id">
                                                    修改用户组名称</div>
                                                <div style="color: #F56C6C;margin-top: 10px;cursor: pointer;"
                                                    @click="deleteDialogVisible = true; groupForm.id = item.id; dialogType = 'deleteGroup'">
                                                    删除</div>
                                            </div>
                                            <template #reference>
                                                <img src="@/assets/images/business/more.svg" alt=""
                                                    v-show="currentGroupIndex === (index + 2)">
                                            </template>
                                        </el-popover>
                                        <span>{{ item.num }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-content">
                        <div class="search-form">
                            <el-input v-model="searchKeyWord" size="small" style="width: 180px;margin-right: 16px;"
                                placeholder="搜索成员" @keyup.enter.native="handleSearch">
                                <template #prefix>
                                    <img src="@/assets/images/business/search.svg" alt=""
                                        style="vertical-align: middle;margin-left: 4px;cursor: pointer;"
                                        @click="handleSearch">
                                </template>
                            </el-input>
                            <el-tooltip class="item" effect="light" content="请至少选择一位成员" placement="top"
                                v-if="(currentGroupIndex === 0 || currentGroupIndex === 1) && currentSelect.length === 0">
                                <el-button disabled size="small"
                                    style="color: #8C8C8C;font-family: PingFangSC-Regular, PingFang SC;font-size: 14px;">批量添加用户组</el-button>
                            </el-tooltip>
                            <el-button type="primary" size="small"
                                v-if="(currentGroupIndex === 0 || currentGroupIndex === 1) && currentSelect.length !== 0"
                                @click="dialogType = 'batchAddGroupAccount'; form.user_group_ids = []; dialogVisible = true"
                                style="font-size: 14px;">批量添加用户组</el-button>
                            <el-tooltip class="item" effect="light" content="请至少选择一位成员" placement="top"
                                v-if="(currentGroupIndex !== 0 && currentGroupIndex !== 1) && currentSelect.length === 0">
                                <el-button disabled size="small"
                                    style="color: #8C8C8C;font-family: PingFangSC-Regular, PingFang SC;font-size: 14px;">批量移除用户组</el-button>
                            </el-tooltip>
                            <el-button type="primary" size="small"
                                v-if="(currentGroupIndex !== 0 && currentGroupIndex !== 1) && currentSelect.length !== 0"
                                @click="dialogType = 'batchDeleteGroupAccount'; deleteDialogVisible = true"
                                style="font-size: 14px;">批量移除用户组</el-button>
                            <el-button type="primary" size="small"
                                v-if="userInfo.crm_id === 91122 || userInfo.crm_id === 27563"
                                @click="dialogType = 'addSubAccount'; dialogVisible = true"
                                style="font-size: 14px;">新增子账号</el-button>
                        </div>
                        <el-table ref="tableRef" :data="tableData" @selection-change="handleSelectionChange"
                            :header-cell-style="{ background: 'var(--el-table-row-hover-background-color)', color: '#262626', 'font-weight': '400' }"
                            style="min-height: 450px">
                            <el-table-column type="selection" width="55" :selectable="selectable" />
                            <el-table-column label="用户名" align="left" header-align="left" show-overflow-tooltip>
                                <template #default="{ row }">
                                    <img :src="row.avatar" alt="">
                                    <span>{{ row.name }}</span>
                                    <span v-if="row.phone === userInfo.phone" style="color: #2B82FF;">（我）</span>
                                </template>
                            </el-table-column>
                            <el-table-column property="phone" label="账号" align="left" header-align="left"
                                show-overflow-tooltip />
                            <el-table-column property="user_group" label="所属用户组" align="left" header-align="left"
                                show-overflow-tooltip />
                            <el-table-column property="manage" label="角色" show-overflow-tooltip />
                            <el-table-column label="可用余额" show-overflow-tooltip
                                v-if="userInfo.crm_id === 91122 || userInfo.crm_id === 27563">
                                <template #default="{ row }">
                                    <span>${{ row.balance }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="left" header-align="left" width="125">
                                <template #default="{ row }">
                                    <el-space wrap>
                                        <el-button type="text" class="operate-button" size="small" @click="editAccount(row)"
                                            v-if="row.is_manage !== 1">编辑</el-button>
                                        <el-button type="text" class="operate-button" size="small"
                                            @click="form.id = row.u_id; dialogType = 'deleteGroupAccount'; deleteDialogVisible = true; form.name = row.name;"
                                            style="color: #F56C6C!important;"
                                            v-if="currentGroupIndex !== 0 && currentGroupIndex !== 1">移出用户组</el-button>
                                        <el-button type="text" class="operate-button" size="small"
                                            v-if="row.is_manage === 1 && userInfo.manage === 1"
                                            @click="handleTransferAdmin">转让超管</el-button>
                                        <el-button type="text" class="operate-button" size="small"
                                            v-if="row.is_manage === 1 && userInfo.manage === 1"
                                            @click="openBalanceDialog">成员余额回收</el-button>
                                        <el-button type="text" class="operate-button" size="small"
                                            @click="form.id = row.u_id; dialogType = 'deleteAccount'; deleteDialogVisible = true;"
                                            style="color: #F56C6C!important;"
                                            v-if="(currentGroupIndex === 0 || currentGroupIndex === 1) && row.phone !== userInfo.phone && row.is_manage !== 1">删除成员</el-button>
                                        <el-button type="text" class="operate-button" size="small"
                                            @click="copyAccountPwd(row)"
                                            v-if="row.is_manage !== 1 && row.password_backup">复制账号密码</el-button>
                                    </el-space>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <el-row class="mb-10" justify="end" style="width: 100%;">
                    <el-pagination v-model:page-size="pageData.pagesize" v-model:current-page="pageData.page"
                        :total="pageData.total" :page-sizes="[5, 10, 20, 40, 60, 80, 100, 200, 300, 400]"
                        @size-change="pagesizeChange" @current-change="pageChange"
                        layout="total, prev, pager, next, sizes,jumper" background />
                </el-row>
            </el-card>
        </el-col>
    </el-row>

    <el-dialog v-model="dialogVisible" :title="dialogTypeLabel" :width="dialogWidth" top="20vh"
        :close-on-click-modal="false" :destroy-on-close="true" custom-class="member-manage-dialog"
        :show-close="dialogType !== 'viewAccountAsset' && dialogType !== 'viewBalanceLog'" @close="nameEditable = false">
        <el-button @click="dialogType = 'editAccountPermission'" type="text"
            v-if="dialogType === 'viewAccountAsset' || dialogType === 'viewBalanceLog'" class="back-button">返回</el-button>
        <!-- 分享链接弹窗 -->
        <div v-if="dialogType === 'invite'">
            <div class="tip">分享此链接，通过此链接注册登录的小伙伴，即可成功加入企业。</div>
            <div class="invite-content">
                <el-input size="small" disabled v-model="inviteUrl" />
                <el-button type="primary" size="small" @click="copyUrl" style="margin-left: 16px;">复制链接</el-button>
            </div>
            <div class="invite-tip">提示：没有企业认证的用户才能加入企业，邀请链接仅有30分钟有效期。</div>
        </div>
        <!-- 修改成员权限弹窗 -->
        <div v-else-if="dialogType === 'editAccountPermission'">
            <el-form label-position="right" label-width="110px" :model="form">
                <el-form-item label="已选择用户">
                    <div v-if="userInfo.crm_id === 91122 || userInfo.crm_id === 27563">
                        <el-input v-model="form.name" size="small" style="width:208px" :disabled="!nameEditable"
                            clearable />
                        <img src="@/assets/images/business/edit.svg" style="vertical-align: middle;margin-left: 8px;"
                            @click="nameEditable = true" v-if="!nameEditable">
                    </div>
                    <span style="color: #262626;" v-else>{{ form.name }}({{ form.phone }})</span>
                </el-form-item>
                <el-form-item label="所属用户组" prop="user_group_ids">
                    <el-checkbox-group v-model="form.user_group_ids">
                        <el-checkbox :label="item.id" v-for="item in userGroup.user_group"><el-tooltip effect="dark"
                                content="" placement="top">
                                <template #content>
                                    {{ item.name }}
                                </template>
                                <span class="user-group-text">{{
                                    item.name }}</span>
                            </el-tooltip></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="角色" prop="role_id">
                    <el-radio-group v-model="form.role_id">
                        <el-radio :label="item.id" v-for="item in roleList"><el-tooltip effect="dark" content=""
                                placement="top">
                                <template #content>
                                    {{ item.name }}
                                </template>
                                <span class="user-group-text">{{
                                    item.name }}</span>
                            </el-tooltip></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="查看可见资产">
                    <el-button type="primary" size="small" @click="viewAccountAsset">广告账户资产</el-button>
                </el-form-item>
                <el-form-item v-if="userInfo.manage === 1">
                    <template #label>
                        <span style="margin-right: 4px;">钱包分配</span>
                        <el-tooltip class="item" effect="light" content="" placement="top">
                            <template #content>
                                <div>
                                    管理员可将自己钱包中的余额分配给企业成员，分配余额后，企业钱包将不对企业成员共享；
                                </div>
                                <div>
                                    企业成员仅能使用管理员分配的余额来对广告账户进行操作；
                                </div>
                                <div>
                                    企业成员对广告账户余额清零，余额将回收到管理员的钱包余额中。
                                </div>
                            </template>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </template>
                    <el-button type="primary" size="small"
                        @click="balanceDialogVisible = true; balanceDialogType = 'adjustMemberBalance'">调整可用余额</el-button>
                    <el-button type="primary" size="small" @click="handleBalanceRecovery"
                        style="width: 104px;margin: 0 10px 0 20px;">余额回收</el-button>
                    <el-button type="primary" size="small" @click="viewBalanceLog">查看流水日志</el-button>
                    <el-row type="flex">
                        <div style="color: #8C8C8C;margin-right: 32px;">此账号当前可用余额：${{ form.balance }}</div>
                        <div style="color: #8C8C8C;">管理员可分配余额：${{ form.dist_balance }}</div>
                    </el-row>
                </el-form-item>
            </el-form>
        </div>
        <!-- 批量添加用户组弹窗 -->
        <div v-else-if="dialogType === 'batchAddGroupAccount'">
            <el-form label-position=" left" label-width="110px" :model="form" ref="groupFormRef">
                <el-form-item label="已选择用户">
                    <div style="display: flex;flex-wrap: wrap;">
                        <span style="margin-right: 30px;color: #262626;" v-for="item in form.name">{{ item
                        }}</span>
                    </div>
                </el-form-item>
                <el-form-item label="所属用户组" prop="user_group_ids">
                    <el-checkbox-group v-model="form.user_group_ids">
                        <el-checkbox :label="item.id" v-for="item in userGroup.user_group"><el-tooltip effect="dark"
                                content="" placement="top">
                                <template #content>
                                    {{ item.name }}
                                </template>
                                <span class="user-group-text">{{
                                    item.name }}</span>
                            </el-tooltip></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
        </div>
        <!-- 编辑用户组名称弹窗 -->
        <div v-else-if="dialogType === 'editGroup'" class="small-dialog">
            <el-form label-position="left" label-width="110px" :model="groupForm" ref="groupFormRef">
                <el-form-item label="用户组名称" :rules="{ required: true, message: '请输入用户组名称', trigger: 'blur' }" prop="name">
                    <el-input v-model="groupForm.name" size="small" clearable placeholder="请输入用户组名称" style="width: 85%;" />
                </el-form-item>
            </el-form>
        </div>
        <!-- 新建用户组弹窗 -->
        <div v-else-if="dialogType === 'addGroup'" class="small-dialog">
            <el-form label-position="left" label-width="110px" :model="groupForm" ref="groupFormRef">
                <el-form-item label="用户组名称" prop="name" :rules="{ required: true, message: '请输入用户组名称', trigger: 'blur' }">
                    <el-input v-model="groupForm.name" size="small" clearable placeholder="请输入用户组名称" style="width: 85%;" />
                </el-form-item>
            </el-form>
        </div>
        <!-- 转让超管弹窗 -->
        <div v-else-if="dialogType === 'transferAdmin'">
            <el-form label-position="left" label-width="120px" :model="form" ref="groupFormRef">
                <el-form-item label="可转让管理员" prop="admin_id" :rules="{ required: true, message: '请选择被转让的管理员' }">
                    <el-radio-group v-model="form.admin_id">
                        <el-radio :label="item.u_id" v-for="item in adminList">
                            <span>{{ item.name }}</span>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </div>
        <!-- 新建子账号弹窗 -->
        <div v-else-if="dialogType === 'addSubAccount'" style="margin-top:30px">
            <el-form label-position="left" label-width="90px" :model="subAccountForm" ref="groupFormRef" :rules="rules">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="subAccountForm.username" size="small" clearable placeholder="请输入用户名" />
                </el-form-item>
                <el-form-item label="登录账号" prop="account">
                    <el-input v-model="subAccountForm.account" size="small" clearable placeholder="请输入登录账号" />
                </el-form-item>
                <el-form-item label="登录密码" prop="password">
                    <el-input v-model="subAccountForm.password" size="small" clearable placeholder="请输入登录密码" type="password"
                        show-password />
                </el-form-item>
            </el-form>
        </div>
        <!-- 成员余额回收弹窗 -->
        <div v-else-if="dialogType === 'memberBalanceRecovery'" class="member-balance-recovery">
            <div class="text">
                是否将其他企业成员分配的余额进行回收?余额回收后,企业成员被分配的可用余额将回收到企业钱包中,同时企业钱包恢复到对所有企业成员共享状态。
            </div>
            <div class="container">
                <div class="header">
                    <div>用户名</div>
                    <div>可回收余额</div>
                </div>
                <div class="account-list">
                    <div v-for="(item, index) in form.allUserBalance" :key="index" class="account-item">
                        <div>{{ item.name }}</div>
                        <div>
                            $ {{ item.balance }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>回收总金额：<span class="recharge-amount">$ {{ form.totalBalance }}</span></div>
            </div>
        </div>
        <!-- 查看广告账户资产弹窗 -->
        <div v-show="dialogType === 'viewAccountAsset'">
            <el-table :data="assetTableData" height="285" style="width: 100%"
                :header-cell-style="{ background: 'var(--el-table-row-hover-background-color)', padding: '0' }">
                <el-table-column prop="name" label="账户名称" align="left" />
                <el-table-column prop="account_id" label="账户ID" align="center" />
                <el-table-column prop="chinese_legal_entity_name" label="营业执照" align="center" />
                <el-table-column prop="account_status" label="账户状态" align="center">
                    <template #default="{ row }">
                        <el-tag :type="row.account_status !== 1 ? 'danger' : 'success'" disable-transitions>{{
                            row.account_status !== 1 ? '被封' : '正常' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="account_balance" label="账户余额">
                    <template #default="{ row }">
                        <span>${{ row.account_balance }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 查看流水日志弹窗 -->
        <div v-show="dialogType === 'viewBalanceLog'" class="view-balance-log-dialog">
            <el-table :data="balanceTableData" height="242" style="width: 100%"
                :header-cell-style="{ background: 'var(--el-table-row-hover-background-color)', padding: '0' }">
                <el-table-column prop="name" label="操作类型" align="left" />
                <el-table-column prop="account_id" label="广告账户" align="left" />
                <el-table-column prop="money" label="金额">
                    <template #default="{ row }">
                        <span>{{ row.balance }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="操作时间" align="center" />
            </el-table>
            <el-pagination v-model:current-page="balancePageData.page" :page-size="5" layout="total, prev, pager, next"
                :total="balancePageData.total" @current-change="handleBalanceLogCurrentChange" />
            <el-row type="flex" justify="end" style="margin-top: 35px;">
                <el-button type="primary" size="small" @click="handleExportBalanceLog">导出日志</el-button>
            </el-row>
        </div>
        <template #footer>
            <span class="dialog-footer" v-if="dialogType !== 'viewAccountAsset' && dialogType !== 'viewBalanceLog'">
                <div v-if="dialogType !== 'invite'">
                    <el-button @click="if (groupFormRef) { groupFormRef.resetFields() }; dialogVisible = false;"
                        size="small">取消</el-button>
                    <el-button type="primary" size="small" @click="handleSubmit()">确认</el-button>
                </div>
                <el-button @click="dialogVisible = false" size="small" v-else>关闭</el-button>
            </span>
        </template>
    </el-dialog>

    <!-- 删除成员弹窗 -->
    <div class="delete-dialog">
        <el-dialog v-model="deleteDialogVisible" width="450px" top="30vh" :close-on-click-modal="false">
            <div class="delete" v-if="dialogType === 'deleteAccount'">
                <img src="@/assets/images/business/warning.png" alt="">
                <div class="delete-title">确定要删除此成员吗？</div>
                <div class="delete-info">注意：删除成员将无法使用此账号再次登录，此账号下的操作及交易记录会保留在管理员账号下。再次邀请将会生成新账号，无法同步删除前的记录！</div>
                <div>
                    <el-button @click="deleteDialogVisible = false" size="small">取消</el-button>
                    <el-button type="danger" size="small" @click="handleDeleteAccount">确认</el-button>
                </div>
            </div>
            <div class="delete" v-else-if="dialogType === 'deleteGroup'">
                <img src="@/assets/images/business/warning.png" alt="">
                <div class="delete-title">确定要删除此用户组吗？</div>
                <div class="delete-info">注意：删除用户组后不会将该组成员移出当前团队，但用户组不可恢复，是否继续?</div>
                <div>
                    <el-button @click="deleteDialogVisible = false" size="small">取消</el-button>
                    <el-button type="danger" size="small" @click="handleDeleteAccount">确认</el-button>
                </div>
            </div>
            <div class="delete" v-else-if="dialogType === 'deleteGroupAccount'">
                <img src="@/assets/images/business/warning.png" alt="">
                <div class="delete-title">是否确认将{{ form.name }}移出{{ userGroup.user_group[currentGroupIndex - 2].name }}
                </div>
                <div>
                    <el-button @click="deleteDialogVisible = false" size="small">取消</el-button>
                    <el-button type="danger" size="small" @click="handleDeleteAccount">确认</el-button>
                </div>
            </div>
            <div class="delete" v-else-if="dialogType === 'batchDeleteGroupAccount'">
                <img src="@/assets/images/business/warning.png" alt="">
                <div class="delete-title">是否确认将{{ form.name.join('，') }}移出{{ userGroup.user_group[currentGroupIndex -
                        2].name }}</div>
                <div>
                    <el-button @click="deleteDialogVisible = false" size="small">取消</el-button>
                    <el-button type="danger" size="small" @click="handleDeleteAccount">确认</el-button>
                </div>
            </div>
        </el-dialog>
    </div>

    <!-- 余额操作弹窗 -->
    <el-dialog v-model="balanceDialogVisible" width="600px" top="35vh" :close-on-click-modal="false"
        :title="balanceDialogType === 'balanceRecovery' ? '余额回收' : '调整成员可用余额'"
        custom-class="member-manage-balance-adjust-dialog" @close="form.balanceAdjustAmount = null;">
        <div v-if="balanceDialogType === 'balanceRecovery'">
            <div style="color:#595959;">是否确认将此账户当前可用余额：${{ form.balance }} 回收到管理员企业钱包中？</div>
        </div>
        <div v-if="balanceDialogType === 'adjustMemberBalance'" class="balance-adjust-dialog">
            <el-form label-position="right" label-width="70px" :model="form" ref="groupFormRef" :rules="rules"
                :hide-required-asterisk="true">
                <el-form-item label="操作类型">
                    <el-select v-model="form.balanceAdjustType" size="small" style="width: 320px;">
                        <el-option label="增加可用余额" :value="1"></el-option>
                        <el-option label="减少可用余额" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="form.balanceAdjustType === 1 ? '增加余额' : '减少余额'" prop="balanceAdjustAmount">
                    <el-input v-model="form.balanceAdjustAmount" size="small" clearable placeholder="请输入调整金额"
                        style="width: 320px;">
                        <template #append>$</template>
                    </el-input>
                </el-form-item>
                <el-row type="flex" class="tip">
                    <div style="margin-right: 32px;">此账号当前可用余额：${{ form.balance }}</div>
                    <div>管理员可分配余额：${{ form.dist_balance }}</div>
                </el-row>
                <div v-show="form.balanceAdjustType === 1" class="tip">请输入要分配给此子账号的金额，新可用余额=当前可用余额+新分配余额
                </div>
                <div v-show="form.balanceAdjustType === 2" class="tip">请输入要回收到管理员企业钱包的金额，新可用余额=当前可用余额-减少金额
                </div>
            </el-form>
        </div>
        <template #footer>
            <el-button
                @click="if (groupFormRef) { groupFormRef.resetFields() }; balanceDialogVisible = false; form.balanceAdjustAmount = null;"
                size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleBalanceAdjust()">确认</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance, computed } from 'vue'
import tools from "@/base/filters/base";
import { getUserGroupList, getUserGroup, getInviteUrl, editUserGroup, addUserGroup, deleteUserGroup, moveoutGroup, editUserPermission, batchAddGroupUser, deleteAccount, getRoleListAll, getAccountAsset, getAdminList, transferAdmin, balanceRecovery, adjustBalance, getBalanceLog, getAllUserBalance, getUserBalance, addSubAccount, exportBalanceLog } from '@/request/api/business'
import { ElMessage, ElLoading } from 'element-plus';
import downloadFile from '@/base/download-file'

const { proxy } = getCurrentInstance()
const searchKeyWord = ref('')
const cardHeight = ref(window.innerHeight - 360)
const userInfo = ref(proxy.$store.getters.userInfo)
const businessAccountList = ref([])
const inviteUrl = ref('')
const dialogVisible = ref(false)
const groupFormRef = ref(null)
const deleteDialogVisible = ref(false)
const balanceDialogVisible = ref(false)
const nameEditable = ref(false)
const dialogType = ref('invite')
const balanceDialogType = ref('balanceRecovery')
const tableData = ref([])
const assetTableData = ref([]) // 账户资产表格数据
const balanceTableData = ref([]) // 流水日志表格数据
const balancePageData = ref({ page: 1, total: 0 })
const pageData = ref({ page: 1, pagesize: 10, total: 0 })
const userGroup = ref({ all_num: 0, num: 0, user_group: [] }) // 用户组
const roleList = ref([]) // 角色列表
const adminList = ref([]) // 可转让超管的管理员列表
const currentGroupIndex = ref(0)
const currentSelect = ref([])
const currentSelectRoleId = ref(null)
const form = ref({
    id: '',
    name: '',
    phone: '',
    user_group_ids: [],
    role_id: null,
    admin_id: null,
    balance: 0,
    dist_balance: 0,
    balanceAdjustAmount: null,
    balanceAdjustType: 1,
    allUserBalance: [],
    totalBalance: 0,
})
// 表单验证规则
const rules = reactive({
    username: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
        { min: 1, message: '用户名长度不少于1个字符', trigger: 'blur' },
    ],
    account: [
        { required: true, message: '请输入登录账号', trigger: 'blur' },
        { min: 3, max: 100, message: '登录账号不得少于3个字符', trigger: 'blur' },
    ],
    password: [
        { required: true, message: '请输入登录密码', trigger: 'blur' },
        { min: 6, max: 12, message: '登录密码长度在 6 到 12 个字符', trigger: 'blur' },
        { pattern: /^[a-zA-Z0-9]+$/, message: '登录密码必须为英文和数字', trigger: 'blur' },
    ],
    balanceAdjustAmount: [
        { required: true, message: '请输入调整金额', trigger: 'blur' },
        { pattern: /^\d+(\.\d+)?$/, message: '调整金额必须为正整数或小数', trigger: 'blur' },
    ],
})
const groupForm = ref({
    id: null,
    name: '',
})

const subAccountForm = ref({
    username: '',
    account: '',
    password: '',
})

const dialogTypeLabel = computed(() => {
    switch (dialogType.value) {
        case 'invite':
            return '邀请链接分享'
        case 'editGroup':
            return '修改用户组名称'
        case 'addGroup':
            return '新建用户组'
        case 'editAccountPermission':
            return '修改成员权限'
        case 'batchAddGroupAccount':
            return '批量添加用户组'
        case 'batchDeleteGroupAccount':
            return '批量移除用户组'
        case 'transferAdmin':
            return '转让超管'
        case 'memberBalanceRecovery':
            return '成员余额回收'
        case 'viewAccountAsset':
            return '查看广告账户资产'
        case 'viewBalanceLog':
            return '查看流水日志'
        case 'addSubAccount':
            return '新增子账号'
        default:
            return '邀请链接分享'
    }
})

const dialogWidth = computed(() => {
    switch (dialogType.value) {
        case 'viewAccountAsset':
            return '998px'
        case 'viewBalanceLog':
            return '998px'
        case 'memberBalanceRecovery':
            return '520px'
        case 'addSubAccount':
            return '580px'
        default:
            return '691px'
    }
})

const selectable = (row) => {
    return row.is_manage !== 1
}

const copyUrl = () => {
    proxy.$copyText(`${userInfo.value.name}邀请你加入企业《${userInfo.value.company}》, 点击链接即可加入: ${inviteUrl.value}`).then(
        e => {
            proxy.$message.success('复制链接成功，立即分享链接！')
        },
        e => {
            proxy.$message.error('复制失败！')
        }
    )
}

const copyAccountPwd = (row) => {
    proxy.$copyText(`account: ${row.phone}\npassword: ${row.password_backup}`).then(
        e => {
            proxy.$message.success('复制账号密码成功！')
        },
        e => {
            proxy.$message.error('复制失败，请刷新后重试')
        }
    )
}

/**
 * 修改成员权限
 * @param {Object} row 表格行数据
 */
const editAccount = (row) => {
    dialogType.value = 'editAccountPermission'
    dialogVisible.value = true
    form.value.id = row.u_id
    form.value.name = row.name
    form.value.phone = row.phone
    getUserGroupList({ type: 4, id: row.u_id }).then(res => {
        form.value.user_group_ids = res.data.map(item => item.id)
    })
    getRoleListAll().then(res => {
        roleList.value = res.data
        form.value.role_id = row.role_id
        currentSelectRoleId.value = row.role_id
    })
    if (userInfo.value.manage !== 1) return
    getUserBalanceInfo()
}

/**
 * 刷新用户组和用户组成员数据
 */
const refreshData = () => {
    getUserGroupList({ type: currentGroupIndex.value === 0 ? 1 : currentGroupIndex.value === 1 ? 2 : 3, id: (currentGroupIndex.value === 0) || (currentGroupIndex.value === 1) ? null : userGroup.value.user_group[currentGroupIndex.value - 2].id }).then((res) => {
        tableData.value = res.user
        pageData.value.total = res.total
        pageData.value.page = 1
    })
    getUserGroup().then((res) => {
        userGroup.value.all_num = res.all_num
        userGroup.value.num = res.num
        userGroup.value.user_group = res.user_group
    })
}

const handleDeleteAccount = () => {
    if (dialogType.value === 'deleteAccount') {
        deleteAccount(form.value.id).then((res) => {
            proxy.$message.success('删除成功！')
            deleteDialogVisible.value = false
            refreshData()
        })
    } else if (dialogType.value === 'deleteGroup') {
        deleteUserGroup(groupForm.value.id).then((res) => {
            proxy.$message.success(res.msg)
            deleteDialogVisible.value = false
            currentGroupIndex.value -= 1
            refreshData()
        })
    } else if (dialogType.value === 'deleteGroupAccount') {
        moveoutGroup({ id: userGroup.value.user_group[currentGroupIndex.value - 2].id, u_ids: form.value.id.toString() }).then(res => {
            proxy.$message.success(res.msg)
            deleteDialogVisible.value = false
            refreshData()
        })
    } else if (dialogType.value === 'batchDeleteGroupAccount') {
        moveoutGroup({ id: userGroup.value.user_group[currentGroupIndex.value - 2].id, u_ids: form.value.id }).then(res => {
            proxy.$message.success(res.msg)
            deleteDialogVisible.value = false
            refreshData()
        })
    }
}

const inviteAccount = () => {
    getInviteUrl().then((res) => {
        inviteUrl.value = res.url
    })
    dialogVisible.value = true;
    dialogType.value = 'invite'
}

const groupIndexChange = (index) => {
    if (index < 2) {
        currentGroupIndex.value = index
        getUserGroupList({ type: index + 1 }).then((res) => {
            tableData.value = res.user
            pageData.value.total = res.total
            pageData.value.page = 1
        })
    } else {
        currentGroupIndex.value = index
        getUserGroupList({ type: 3, id: userGroup.value.user_group[index - 2].id }).then((res) => {
            tableData.value = res.user
            pageData.value.total = res.total
            pageData.value.page = 1
        })
    }
}

const handleSelectionChange = (val) => {
    console.log(val);
    currentSelect.value = val
    form.value.name = val.map(item => item.name)
    form.value.id = val.map(item => item.u_id).join(',')
}

const pageChange = (val) => {
    pageData.value.page = val
    getUserGroupList({ type: currentGroupIndex.value === 0 ? 1 : currentGroupIndex.value === 1 ? 2 : 3, id: (currentGroupIndex.value === 0) || (currentGroupIndex.value === 1) ? null : userGroup.value.user_group[currentGroupIndex.value - 2].id, page: pageData.value.page, pagesize: pageData.value.pagesize }).then((res) => {
        tableData.value = res.user
        pageData.value.total = res.total
    })
}

const pagesizeChange = (val) => {
    pageData.value.pagesize = val
    getUserGroupList({ type: currentGroupIndex.value === 0 ? 1 : currentGroupIndex.value === 1 ? 2 : 3, id: (currentGroupIndex.value === 0) || (currentGroupIndex.value === 1) ? null : userGroup.value.user_group[currentGroupIndex.value - 2].id, page: pageData.value.page, pagesize: pageData.value.pagesize }).then((res) => {
        tableData.value = res.user
        pageData.value.total = res.total
        pageData.value.page = 1
    })
}

const handleSubmit = async () => {
    if (dialogType.value === 'editGroup') {
        await groupFormRef.value.validate((valid) => {
            if (valid) {
                editUserGroup({ id: groupForm.value.id, name: groupForm.value.name }).then((res) => {
                    proxy.$message.success(res.msg)
                    if (groupFormRef.value) {
                        groupFormRef.value.resetFields()
                    }
                    dialogVisible.value = false
                    refreshData()
                })
            } else {
                return false
            }
        })
    } else if (dialogType.value === 'addGroup') {
        await groupFormRef.value.validate((valid) => {
            if (valid) {
                addUserGroup({ name: groupForm.value.name }).then((res) => {
                    proxy.$message.success(res.msg)
                    if (groupFormRef.value) {
                        groupFormRef.value.resetFields()
                    }
                    dialogVisible.value = false
                    refreshData()
                })
            } else {
                return false
            }
        })
    } else if (dialogType.value === 'editAccountPermission') {
        if (!form.value.name && (userInfo.crm_id === 91122 || userInfo.crm_id === 27563)) return proxy.$message.error('请输入用户名')
        editUserPermission({
            u_id: form.value.id,
            user_group_ids: form.value.user_group_ids.join(','),
            role_id: form.value.role_id,
            name: form.value.name,
        }).then(res => {
            proxy.$message.success(res.msg)
            if (groupFormRef.value) {
                groupFormRef.value.resetFields()
            }
            dialogVisible.value = false
            if (form.value.role_id !== currentSelectRoleId.value && form.value.phone === userInfo.value.phone) {
                proxy.$message({
                    message: '修改角色成功，请重新登录',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                        window.localStorage.removeItem('userInfo')
                        window.localStorage.removeItem('user')
                        proxy.$store.commit('changeUserToken', {})
                        window.location.reload()
                    }
                })
            } else {
                refreshData()
            }
        })
    } else if (dialogType.value === 'batchAddGroupAccount') {
        batchAddGroupUser({
            u_ids: form.value.id,
            user_group_ids: form.value.user_group_ids.join(',')
        }).then(res => {
            proxy.$message.success(res.msg)
            if (groupFormRef.value) {
                groupFormRef.value.resetFields()
            }
            dialogVisible.value = false
            refreshData()
        })
    } else if (dialogType.value === 'transferAdmin') {
        await groupFormRef.value.validate((valid) => {
            if (valid) {
                transferAdmin(form.value.admin_id).then(res => {
                    if (groupFormRef.value) {
                        groupFormRef.value.resetFields()
                    }
                    dialogVisible.value = false
                    proxy.$message({
                        message: res.msg,
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            window.location.reload()
                        }
                    })
                })
            } else { return false }
        })
    } else if (dialogType.value === 'memberBalanceRecovery') {
        balanceRecovery().then(res => {
            proxy.$message.success(res.msg)
            dialogVisible.value = false
            refreshData()
        })
    } else if (dialogType.value === 'addSubAccount') {
        await groupFormRef.value.validate((valid) => {
            if (valid) {
                addSubAccount({
                    username: subAccountForm.value.username,
                    account: subAccountForm.value.account,
                    password: subAccountForm.value.password
                }).then(res => {
                    proxy.$message.success(res.msg)
                    dialogVisible.value = false
                    refreshData()
                })
            } else { return false }
        })
    }
}

const handleSearch = () => {
    getUserGroupList({ type: currentGroupIndex.value === 0 ? 1 : currentGroupIndex.value === 1 ? 2 : 3, id: (currentGroupIndex.value === 0) || (currentGroupIndex.value === 1) ? null : userGroup.value.user_group[currentGroupIndex.value - 2].id, page: pageData.value.page, pagesize: pageData.value.pagesize, keyword: searchKeyWord.value }).then((res) => {
        tableData.value = res.user
        pageData.value.total = res.total
        pageData.value.page = 1
    })
}

const handleTransferAdmin = async () => {
    const res = await getAdminList()
    if (res.data.length === 0) return proxy.$message.error('暂未设置管理员')
    adminList.value = res.data
    dialogType.value = 'transferAdmin'
    dialogVisible.value = true
}

const handleBalanceRecovery = () => {
    if (parseFloat(form.value.balance) === 0) return proxy.$message.error('此账号当前无可用余额，无法进行余额回收！')
    balanceDialogType.value = 'balanceRecovery'
    balanceDialogVisible.value = true
}

const openBalanceDialog = async () => {
    await getAllUserBalance().then(res => {
        form.value.allUserBalance = res.data
        form.value.totalBalance = res.total_balance
    })
    if (form.value.allUserBalance.length === 0) return proxy.$message.error('暂无可回收余额！')
    dialogVisible.value = true;
    dialogType.value = 'memberBalanceRecovery';
}

/** 查看用户可用额度和可分配额度 */
const getUserBalanceInfo = () => {
    getUserBalance(form.value.id).then(res => {
        form.value.balance = res.balance
        form.value.dist_balance = res.dist_balance
    })
}

/** 调整成员可用余额 */
const handleBalanceAdjust = async () => {
    if (balanceDialogType.value === 'balanceRecovery') {
        balanceRecovery(form.value.id.toString()).then(res => {
            proxy.$message.success(res.msg)
            getUserBalanceInfo()
            balanceDialogVisible.value = false
            refreshData()
        })
    } else if (balanceDialogType.value === 'adjustMemberBalance') {
        if (parseFloat(form.value.balance) === 0 && form.value.balanceAdjustType === 2) return proxy.$message.error('此账号当前无可用余额，无法进行减少！')
        await groupFormRef.value.validate((valid) => {
            if (valid) {
                adjustBalance({
                    u_id: form.value.id.toString(),
                    money: parseFloat(form.value.balanceAdjustAmount),
                    type: form.value.balanceAdjustType
                }).then(res => {
                    proxy.$message.success(res.msg)
                    getUserBalanceInfo()
                    balanceDialogVisible.value = false
                    refreshData()
                })
            } else { return false }
        })
    }
}

/** 查看账户可见资产 */
const viewAccountAsset = () => {
    dialogType.value = 'viewAccountAsset'
    getAccountAsset(form.value.id).then(res => {
        assetTableData.value = res.data
    })
}

/** 查看流水日志 */
const viewBalanceLog = () => {
    dialogVisible.value = true;
    dialogType.value = 'viewBalanceLog'
    getBalanceLog({
        u_id: form.value.id,
        page: balancePageData.value.page
    }).then(res => {
        balanceTableData.value = res.data
        balancePageData.value.total = res.total
    })
}

/**
 * 流水日志分页切换
 * @param {Number} val 当前页码
 */
const handleBalanceLogCurrentChange = (val) => {
    if (typeof val === 'number') balancePageData.value.page = val
    getBalanceLog({
        u_id: form.value.id,
        page: balancePageData.value.page
    }).then(res => {
        balanceTableData.value = res.data
        balancePageData.value.total = res.total
    })
}

/** 导出流水日志 */
const handleExportBalanceLog = async () => {
    if (balanceTableData.value.length === 0) {
        return ElMessage.error('暂无数据')
    }
    const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
    })
    let res = await exportBalanceLog(form.value.id)
    // 处理返回的文件流
    downloadFile(res, '流水日志')
    loading.close()
}

onMounted(() => {
    refreshData()
})
</script>

<style lang="less">
.member-manage-dialog {
    .el-dialog__title {
        color: #262626;
    }

    .el-dialog__body {
        padding-top: 10px;
        padding-bottom: 0;
    }

    .el-table {
        border: 1px solid #F0F0F0;
    }

    .el-table tr {
        height: 40px;

        td {
            padding: 0;
        }
    }

    .back-button {
        position: absolute;
        right: 20px;
        top: 10px;
    }

    .el-form-item__label {
        padding: 0 20px 0 0;
    }

    .el-dialog__footer {
        padding-bottom: 40px !important;
    }

    .small-dialog {
        padding: 30px 20px 0 20px;
    }
}

.member-manage-balance-adjust-dialog {
    .el-dialog__title {
        color: #262626;
    }

    .el-form-item__content .el-input-group {
        vertical-align: middle;
    }
}
</style>
<style lang="less" scoped>
.top-card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .business-info {
        display: flex;
        align-items: center;

        :deep(.el-avatar--square) {
            border-radius: 8px;
            background: #ffffff!important;
        }

        .info-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 20px;
            height: 90px;

            .info-content-top {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .company {
                    font-size: 22px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #262626;
                    margin-right: 16px;
                }
            }

            .info-content-center {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2B82FF;
                margin-bottom: 8px;

            }

            .info-content-bottom {
                display: flex;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8C8C8C;

                div {
                    margin-right: 80px;
                }

                span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #262626;
                }
            }
        }
    }

    .invite {
        height: 90px;
        display: flex;

        .guide {
            color: #2b82ff;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            text-decoration: none;
            margin-right: 16px;
            line-height: 29px;
            height: 29px;
            display: inline-block;
        }

        .invite-box {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ECF5FF;
            border-radius: 2px;
            cursor: pointer;
            height: 19px;
            padding: 5px 16px;

            img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }

            span {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #2B82FF;
            }
        }
    }
}

.bottom-card {
    .empty {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 320px;
            height: 260px;
            margin-bottom: 20px;
        }

        .empty-title {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #262626;
            margin-bottom: 8px;
        }

        .empty-info {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #595959;
            margin-bottom: 25px;
        }

        .invite-button {
            width: 130px;
            height: 32px;
            background: #2B82FF;
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.043);
            border-radius: 2px;
            font-size: 14px;
            font-family: Source Han Sans-Regular, Source Han Sans;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;
            text-align: center;
            line-height: 32px;
        }
    }

    .left-content {
        width: 236px;
        display: flex;
        flex-direction: column;

        .content-info {
            width: 236px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            margin-bottom: 20px;

            &>span:nth-child(1) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #262626;
            }

            &>span:nth-child(2) {
                max-width: 140px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8C8C8C;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .group-container {
            min-height: 73%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 4px;
            border: 1px solid #E4E4E5;
            padding: 16px;

            .add-group {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40px;
                border-radius: 4px;
                opacity: 1;
                border: 1px solid #E4E4E5;
                cursor: pointer;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }

                span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2B82FF;
                }
            }

            .group-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 16px;
                width: 100%;
                height: calc(100% - 56px);
                overflow: auto;
                padding: 0 16px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #C0C0C5;
                    border-radius: 10px;
                }

                .active-group {
                    background: #F5F7FA;
                    border-radius: 4px;
                }

                .group-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 40px;
                    cursor: pointer;
                    padding: 0 8px;
                    box-sizing: border-box;

                    &>span:first-child {
                        max-width: 130px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    span {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #595959;
                        line-height: 40px;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 12px;
                        vertical-align: middle;
                    }

                    &:hover {
                        background: #F5F7FA;
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    .right-content {
        width: calc(100% - 208px);
        padding-left: 8px;

        .operate-button {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            padding: 0;
            min-height: 19px;
        }

        .search-form {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 12px;

            :deep(.el-input__inner) {
                &::-webkit-input-placeholder {
                    color: #8C8C8C;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                }
            }
        }

        :deep(.el-table) {
            margin-top: 0;

            &::before {
                display: none;
            }

            tr {
                height: 64px;

                td {
                    padding: 0;
                }

                th {
                    padding: 0;
                }
            }
        }
    }

}

.tip {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #595959;
    margin-bottom: 8px;
    margin-top: 10px;
}

.invite-content {
    display: flex;
    align-items: center;
}

.invite-tip {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #8C8C8C;
    margin-top: 8px;
    margin-bottom: 10px;
}

.user-group-text {
    display: block;
    color: #262626;
    font-family: PingFangSC-Regular, PingFang SC;
    width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.view-balance-log-dialog {
    :deep(.el-pagination) {
        width: 100%;
        display: flex;

        .el-pagination__total {
            flex: 1;
        }

        .el-pager {
            color: #606266;
            font-weight: 500;
        }
    }
}


.balance-adjust-dialog {
    :deep(.el-input-group__append, .el-input-group__prepend) {
        padding: 0 12px;
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #262626;
    }

    .tip {
        margin-left: 70px;
        color: #8C8C8C;
        font-size: 12px;
    }
}


.member-balance-recovery {
    .text {
        font-size: 14px;
        color: #595959;
        font-family: PingFangSC-Regular, PingFang SC;
        margin-top: 8px;
        margin-bottom: 16px;
    }

    .container {
        border-radius: 2px;
        border: 1px solid #D9D9D9;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 16px;
            height: 38px;
            background: #F5F7FA;
            border-bottom: 1px solid #D9D9D9;

            div {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                color: #8C8C8C;
                line-height: 38px;
            }

            &>div:first-child {
                flex: 1;
                text-align: left;
                margin-right: 16px;
                border-right: 1px solid #D9D9D9;
            }

            &>div:nth-child(2) {
                width: 160px;
                text-align: left;
            }
        }

        .account-list {
            max-height: 194px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: #C1C1C1;
            }

            div,
            span {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #595959;
            }

            .account-item {
                height: 38px;
                padding-left: 18px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #D9D9D9;

                &>div:first-child {
                    flex: 1;
                    text-align: left;
                    height: 100%;
                    line-height: 38px;
                    margin-right: 16px;
                    border-right: 1px solid #D9D9D9;
                }

                &>div:nth-child(2) {
                    width: 160px;
                    text-align: left;
                }
            }

            .account-item:last-child {
                border-bottom: none;
            }
        }
    }

    .footer {
        margin-top: 30px;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;

        .recharge-amount {
            font-size: 20px;
            font-family: D-DIN, D-DIN;
            color: #F56C6C;
        }
    }
}

.delete-dialog {
    :deep(.el-dialog__body) {
        padding-top: 5px;
    }

    .delete {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
        }

        .delete-title {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #262626;
            margin-bottom: 16px;
        }

        .delete-info {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #595959;
            margin-bottom: 40px;
        }

        :deep(.el-button+.el-button) {
            margin-left: 16px;
        }
    }
}
</style>